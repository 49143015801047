<template>
    <div class='grid'>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco dei Percorsi Formativi aziendali</h4>
                </div>
                <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters' filterDisplay='menu' :loading='loading'
                           responsiveLayout='scroll'
                           >
                    <template #header>
                        <ListHeader
                            v-model="filters['global'].value"
                            @clear='clearFilter()'
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                    </Column>

                    <Column field='last_update' header='Ultima modifica' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ unix2date(slotProps.data.last_update) }}
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='pointer ml-2'
                            title='Apri in nuovo Tab'
                            :style='{color: colors.bg.HELP}'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='pointer ml-2'
                            title='Apri nello stesso Tab'
                            :style='{color: colors.bg.INFO}'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                        </template>
                    </Column>

                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Cont/B2BPaths');
const commonStore = createNamespacedHelpers('Common');

import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';
import dd_companies from '../../../mixins/dd_companies';

export default {
    components: {
        ListHeader
    },
    data() {
        return {
            filters: null,
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading']),
        companyName(){
            if (this.company_id > 0) {
                const company = this.getCompany(this.company_id);
                if (company) return company.name;
            }
            return "Percorso di " + this.company_id;
        }
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
    },
    props: {
        company_id: {
            default: 0,
            type: Number
        }
    },
    methods: {
        ...mapActions(['fetchList']),
        ...commonStore.mapMutations(['setExtraBreadcrumbs']),
        refreshList() {
            if (!this.company_id) return;
            this.fetchList({company_id: this.company_id}).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({});
        },
        clearFilter() {
            this.initFilters();
        },
        setPageBreadcrumbs(){
            this.setExtraBreadcrumbs([
                { label: this.companyName, to: '/app/users/companies/'+this.company_id},
                { label: 'Percorsi' }
            ]);
        },
    },
    mixins: [
        Notifications,
        Navigations,
        dd_companies
    ],
    watch: {
        company_id() {
            this.refreshList();
        },
        dd_mapped_companies(){
            this.setPageBreadcrumbs();
        }
    }
};
</script>
